import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import { AuthContext } from "./context/AuthContext";
import { useContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

function App() {

  const {currentUser} = useContext(AuthContext)


  const RequireAuth = ({children}) => {
    return currentUser ? (children) : <Navigate to="/login"/>
  }

  return ( 
     <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" >
            <Route path="login" element={<Login/>} />
            <Route index element={<RequireAuth><Home/></RequireAuth>}/>
            
            <Route path="creators">
              <Route index element={<RequireAuth><List/></RequireAuth>} />
              <Route path=":userId" element={<RequireAuth><Single/></RequireAuth>} />
              <Route path="new" element={<RequireAuth><New/></RequireAuth>} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

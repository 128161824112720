import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./new.scss"
import { collection, addDoc } from "firebase/firestore"
import { db } from "../../firebase"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const New = () => {

    const [data, setData] = useState({});
    const navigate = useNavigate();

    const handleInput = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        setData({...data, [id]: value })
    }

    const handleAdd = async (e) => {
        e.preventDefault()
        await addDoc(collection(db, "creators"), data);
        navigate(-1)
    }

    return (
        <div className="new">
            <Sidebar/>
            <div className="newContainer">
                <Navbar/>
                <div className="top">
                    <h1>Creatorin hinzufügen</h1>
                </div>
                <div className="bottom">
                    <div className="left">
                        <img src="https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg" alt="" />
                    </div>
                    <div className="right">
                        <form onSubmit={handleAdd}>
                            <div className="formInput">
                                <label>Künstlername</label>
                                <input id="name" type="text" placeholder="john doe" onChange={handleInput}/>
                            </div>

                            <div className="formInput">
                                <label>E-Mail</label>
                                <input id="email" type="email" placeholder="john.doe@example.com" onChange={handleInput} />
                            </div>

                            <div className="formInput">
                                <label>Passwort</label>
                                <input id="password" type="password" onChange={handleInput} />
                            </div>

                            <div className="formInput">
                                <label>Views</label>
                                <input id="views" type="number" placeholder="12" onChange={handleInput} />
                            </div>

                            <div className="formInput">
                                <label>Likes</label>
                                <input id="likes" type="number" placeholder="12" onChange={handleInput} />
                            </div>

                            <div className="formInput">
                                <label>Kommentare</label>
                                <input id="comments" type="number" placeholder="12" onChange={handleInput} />
                            </div>

                            <div className="formInput">
                                <label>Nachricht</label>
                                <input id="message" type="text" placeholder="Nachricht" onChange={handleInput} />
                            </div>

                            <div className="formInput">
                                <label>Gruppe</label>
                                <input id="group" type="text" placeholder="X" onChange={handleInput} />
                            </div>
                            
                            <button type="submit">Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default New
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBRjo6zjijhDPCHp827QZQ4y5Dkk6kTpRA",
  authDomain: "elem-b5854.firebaseapp.com",
  projectId: "elem-b5854",
  storageBucket: "elem-b5854.appspot.com",
  messagingSenderId: "427698967093",
  appId: "1:427698967093:web:eff6aaa79eec7fcea0977c"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth()
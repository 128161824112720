import "./datatable.scss"
import { DataGrid } from '@mui/x-data-grid';
import { collection, deleteDoc, doc, onSnapshot} from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../firebase";

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Künstlername', width: 130 },
    { field: 'email', headerName: 'E-Mail', width: 130 },
    { field: 'password', headerName: 'Passwort', width: 130 },
    { field: 'views', headerName: 'Views', type: "number", width: 70},
    { field: 'likes', headerName: 'Likes', type: "number", width: 70},
    { field: 'comments', headerName: 'Kommentare', type: "number", width: 100},
    { field: 'message', headerName: 'Nachricht', width: 500},
    { field: 'group', headerName: 'Gruppe', width: 130}
  ];

const Datatable = () => {
    const [data, setData] = useState([]);

    useEffect( () => {
        const unsub = onSnapshot(collection(db, "creators"), (snapShot) => {
            let list = [];

            snapShot.docs.forEach(doc => {
                list.push( { id: doc.id, ...doc.data()});
            });
            setData(list);
        }, (error) => {
            console.log(error);
        });

        return () => {
            unsub();
        }
    }, []);

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, "creators", id));
            setData(data.filter((item) => item.id !== id));
        } catch (err) {
            console.log(err)
        }
    }

    const actionColumn = [{ field: "action", headerName: "Action", width: 200, renderCell:(params) => {
        return (
            <div className="cellAction">
                <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div>
            </div>
        )
    }}]
    return (
        <div className="datatable">
            <DataGrid
                className="datagrid"
                rows={data}
                columns={columns.concat(actionColumn)}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        }
                    }
                }}
                pageSize={9}
                rowsPerPageOptions={[25, 50, 100]}
                checkboxSelection
            />
        </div>
    )
}

export default Datatable
import "./list.scss"

const List = () => {
    return (
        <div>List</div>
    )
}

export default List

